const order = {
  orders: {
    ALLOWED_COOKING_TIME_ERROR: "ERROR: Tiempo de cocina seleccionado no válido",
    UNAVAILABLE_ESTABLISHMENT: "ERROR: Conecta el establecimiento para crear la orden",
    UNAVAILABLE_ESTABLISHMENT_SCHEDULE: "ERROR: El establecimiento se encuentra en pausa",
    ESTABLISHMENT_NOT_APPROVED: "ERROR: El establecimiento no se encuentra aprovado",
    ESTABLISHMENT_XPRESS_NOT_ACTIVE: "ERROR: Xpress no activado en establecimiento",
    EMPTY_ORDER: "ERROR: Carrito vacío",
    SHIPPING_COST_EXCEEDS_MAX: "ERROR: Costo de envío excede máximo permitido",
    SHIPPING_COST_LESS_THAN_MIN: "ERROR: Costo de envío es menor a la tarifa calculada",
    MIN_ALLOWED_ELAPSED_COOKING_TIME: "ERROR: No se permite liberar orden antes de 25 min transcurridos",
    MIN_ALLOWED_COOKING_TIME: "ERROR: No se permite liberar orden antes de 10 min transcurridos",
    ERROR: "ERROR"
  },
  availability: {
    NO_AVAILABILITY_ERROR: "No puede modificar disponibilidad ya que existen extras requeridos sin disponibilidad",
    productExtraUpdated (totalProducts, extraName) {
      return `Se han actualizado un total de ${totalProducts} productos debido a que el extra ${extraName} es requerido y su estado se ha modificado a agotado`
    }
  },
  address: {
    INVALID_DISTANCE: "ERROR: Dirección excede nuestro rango de operación",
    USER_HAS_ACTIVE_ODER: "ERROR: El usuario tiene orden en curso"
  }
};

export default order;
